<template>
  <b-row>
    <b-col cols="12">
      <b-card no-body>
        <b-card-header>
          <b-card-title>Öğrenilmiş Dersler</b-card-title>
        </b-card-header>
        <b-table-simple>
          <b-thead>
            <b-tr>
              <b-th>Ders Adı</b-th>
              <b-th />
              <b-th class="text-center width-50 text-nowrap">
                <FeatherIcon icon="DownloadIcon" />
              </b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr
              v-for="item in dataList"
              :key="item.id"
            >
              <b-td>
                <div class="font-weight-bold">
                  {{ item.title }}
                </div>
                <div class="font-small-2 text-muted">
                  {{ item.content }}
                </div>
              </b-td>
              <b-td class="width-200 text-nowrap">
                <div class="text-warning font-weight-bold">
                  {{ moment(item.lesson_date).format('DD.MM.YYYY') }}
                </div>
              </b-td>
              <b-td class="text-center width-50 text-nowrap">
                <b-button
                  v-if="item.file"
                  :href="folderUrl + item.file"
                  target="_blank"
                  variant="primary"
                  size="sm"
                >
                  <FeatherIcon icon="DownloadIcon" /> Dosya İndir
                </b-button>
                <div v-else>
                  -
                </div>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
import {
  BCard, BCardHeader, BCardTitle, BTableSimple, BThead, BTbody, BTr, BTh, BTd, BButton,
} from 'bootstrap-vue'

export default {
  name: 'LessonsLearned',
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
    BButton,
  },
  data() {
    return {
      folderUrl: `${this.$store.state.app.baseURL}/media/portal/lessons_learned/`,
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['lessonsLearned/dataList']
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    getDataList() {
      this.$store.dispatch('lessonsLearned/getDataList')
    },
  },
}
</script>
